const initialState = {
    data: [],
    total: 1,
    params: {},
    detailList: {},
    landTypeList: {},
    activityLogList: [],
    scheduledCasesData: []
}

const register13FormReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_13CASE_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            };

        case "CREATE_REGISTER_13":
            return {
                ...state,
            };
        case "UPDATE_REGISTER_13":
            return {
                ...state,
            };
        case "GET_SCHEDULED_CASES":
            return {
                ...state,
                scheduledCasesData: action.data
            };

        case "GET_SECTION_132_DATA_BY_ID":
            return {
                ...state,
                detailList: action.data
            };

        case "UPDATE_SECTION_13_FROM_CALENDAR":
            return {
                ...state,
            };

        case "GET_ACTIVE_COURTS":
            return {
                ...state,
                activeCourtData: action.data
            };

        case "GET_CASE_ACTIVITY_LOGS":
            return {
                ...state,
                activityLogList: action.data
            };

        default:
            return { ...state }
    }
}
export default register13FormReducer
