const initialState = {
    data: [],
    total: 1,
    params: {},
    detailList: {},
    landTypeList: {},
    activityLogList: []

}

const SchemeListReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_SCHEME':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }

        // case 'CREATE_WARD':
        //     return { ...state }

        // case 'UPDATE_WARD':
        //     return { ...state }

        case "GET_WARD_DATA":
            return {
                ...state,
                activeWardData: action.data,
            };

        case "GET_SCHEME_TYPE_DATA":
            return {
                ...state,
                schemeTypeData: action.data,
            };
        case "GET_DETAIL_LIST":
            return {
                ...state,
                detailList: action.data,
            };

        case "GET_LOGS_LIST":
            return {
                ...state,
                activityLogList: action.data,
            };
        case "GET_LAND_TYPE_LIST":
            return {
                ...state,
                landTypeList: action.data,
            };

        default:
            return { ...state }
    }
}
export default SchemeListReducer
