const initialState = {
    data: [],
    total: 1,
    params: {},
    detailList: {},
    landTypeList: {},
    activityLogList: []

}

const form14Part1Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_FORM_14_PART1':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            };

        case "CREATE_FORM_14_PART1":
            return {
                ...state,
            };

        case "UPDATE_FORM_14_PART1":
            return {
                ...state,
            };


        default:
            return { ...state }
    }
}
export default form14Part1Reducer
