/**
 * Hulk - A Material Design Admin Template
 * Copyright 2022 All Rights Reserved
 * Made Wih Love
 * Created By The Iron Network, LLC
 */

import * as ReactDOMClient from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

// Initial render: Render an element to the root.
root.render(<App />);

reportWebVitals();