const initialState = {
    data: [],
    total: 1,
    params: {},
    detailList: {},
    landTypeList: {},
    activityLogList: []

}

const LegalHeirReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_HEIR_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            };

        case "CREATE_LEGAL_HEIR":
            return {
                ...state,
            };
        case "GET_ACCEPTED_SCHEME_DATA_FOR_LEGAL":
            return {
                ...state,
                schemeData: action.data
            }

        default:
            return { ...state }
    }
}
export default LegalHeirReducer
