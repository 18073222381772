import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { toggleMenu } from 'Redux/actions';

function BarEchart(props) {
	const history = useHistory(); // Use useHistory hook

	const dispatch = useDispatch();

	const {
		seriesData,
		height,
		verticalText,
		annexureName,
		isEstate1,
		isEstate2,
		isLegal,
		// isShowPercentage,
		isLegalHeir,
		isRegisterOfSec13,
		isForm14Part1,
		isForm14Part2,
		isRegisterOfAnnexure2,
		isRegisterOfSection33,
		isRegisterOfAgreement,
		paidRehabDept,
		CAId,
		isCAForm
	} = props;

	// Extract names for the xAxis labels from seriesData
	const xAxisLabels = seriesData ? seriesData.map(series => series.name) : [];

	/**
	 * function to get all the required options
	 * */
	// const getOptions = () => {
	// 	return {
	// 		tooltip: {},
	// 		xAxis: {
	// 			name: "Wards",
	// 			data: xAxisLabels
	// 		},
	// 		yAxis: {
	// 			name:"Schemes"
	// 		},
	// 		series: [{
	// 			name: ['Ward'],
	// 			type: 'bar',
	// 			data: seriesData
	// 		}],
	// 		label: {
	// 			show: true,
	// 			position: 'top',
	// 			formatter: '{c}',
	// 			color: "black",
	// 			fontWeight: 'bold' // Make the label text bold
	// 		}
	// 	}
	// }

	// const getOptions = () => {
	// 	return {
	// 		tooltip: {},
	// 		xAxis: {
	// 			data: xAxisLabels,
	// 			name: 'Ward Names',
	// 			nameLocation: 'middle',
	// 			nameTextStyle: {
	// 				fontWeight: 'bold',
	// 				padding: 20
	// 			}
	// 		},
	// 		yAxis: {
	// 			name: 'No. of Schemes',
	// 			nameLocation: 'middle',
	// 			nameTextStyle: {
	// 				fontWeight: 'bold',
	// 				padding: 30
	// 			}
	// 		},
	// 		series: [{
	// 			name: 'Ward',
	// 			type: 'bar',
	// 			data: seriesData,
	// 			label: {
	// 				show: true,
	// 				position: 'top',
	// 				formatter: '{c}',
	// 				color: "black",
	// 				fontWeight: 'bold'
	// 			}
	// 		}]
	// 	};
	// }
	// const total = seriesData.reduce((sum, item) => sum + item.value, 0);

	const getOptions = () => {
		return {
			tooltip: {},
			xAxis: {
				data: xAxisLabels,
				name: 'Wards',
				nameLocation: 'middle',
				nameTextStyle: {
					// fontWeight: 'bold',
					padding: 20
				},
				axisLabel: {
					rotate: 45, // Rotate labels to prevent overlap
					interval: 0, // Show all labels
					hideOverlap: true, // Automatically hide overlapping labels
				}
			},
			yAxis: {
				name: verticalText ? verticalText : 'No. of Schemes',
				nameLocation: 'middle',
				nameTextStyle: {
					// fontWeight: 550,
					padding: 20,
					fontSize: 13,
					color: 'black'
				}
			},
			series: [{
				name: 'Ward',
				type: 'bar',
				data: seriesData,
				barMaxWidth: 30, // Set the maximum width of each bar
				label: {
					show: true,
					position: 'top',
					// formatter: ({ value }) => {
					// 	const percentage = (value / total * 100).toFixed(2);
					// 	return isShowPercentage ? `${percentage}%` : `${value}`;
					// },
					color: "black",
					
				},
			}]
		};
	}

	function onChartClick(param) {

		if (annexureName) {
			history.push({
				pathname: '/app/schemes',
				state: { annexureData: { annexureName: annexureName }, wardDetail: param?.data }
			});

			dispatch(toggleMenu(3));
		} else if (isEstate1) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/estate-dept/form-1',
				state: { stateData: obj }
			});
		} else if (isEstate2) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/estate-dept/form-2',
				state: { stateData: obj }
			});
		} else if (isLegal) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/create-case',
				state: { stateData: obj }
			});
		} else if (isLegalHeir) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/collector/legal-heir',
				state: { stateData: obj }
			});
		} else if (isRegisterOfSec13) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/collector/register-case',
				state: { stateData: obj }
			});
		} else if (isForm14Part1) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/collector/form-14-part-1',
				state: { stateData: obj }
			});
		} else if (isForm14Part2) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/collector/form-14-part-2',
				state: { stateData: obj }
			});
		} else if (isRegisterOfAnnexure2) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/collector/register-annexure-2',
				state: { stateData: obj }
			});
		} else if (isRegisterOfSection33) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/collector/register-section-33-38',
				state: { stateData: obj }
			});
		} else if (isRegisterOfAgreement) {
			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/collector/register-of-agreement',
				state: { stateData: obj }
			});
		} else if (paidRehabDept) {

			const obj = {
				ward_id: param?.data,
			}
			history.push({
				pathname: '/app/dept/paid-rehabilitation',
				state: { stateData: obj }
			});

		} else if (isCAForm) {

			const obj = {
				ward_id: param?.data,
				ca_number: CAId?.[0]?._id
			}
			history.push({
				pathname: '/app/schemes',
				state: { CAFilter: obj }
			});

		} else {
			history.push({
				pathname: '/app/schemes',
				state: { wardDetail: param?.data }
			});

			// To show selected schemes page in menu item.
			dispatch(toggleMenu(3));
		}
	};

	return (
		<ReactEcharts
			option={getOptions()}
			style={{ height: height, width: '100%' }}
			opts={{ renderer: 'svg' }}
			onEvents={{
				'click': onChartClick,
			}}
		/>
	);
}

BarEchart.defaultProps = {
	seriesData: null,
	height: '400px' // Example default height
}

export { BarEchart };
